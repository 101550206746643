import React, { Component, useState } from "react"
import styled from "styled-components"
import { slide as SlideMenu } from "react-burger-menu"

import { Helmet } from "react-helmet"
import favicon from "../img/favicon.png"
import { QesetKhabrAd } from "./qesetKhabrAd"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
//import StaticHeader from "../templates/staticHeader.js"
import EmailSubscription from "../templates/emailSubscription.js"

import Summary, { SummaryCard } from "../components/summaryPreview.js"

import Button from "../components/appleButton.js"

import logo from "../img/Engzketab_Logo.png"
import { collectionNamesMappedForSEO, colors} from "../base/constants"


import { Container, AppleButton, LineSeparator } from "./variants"

import {
  WebsiteMenu,
  StaticHeader,
  Menu,
} from "../components/website/headerComponents"
import { Footer } from "../components/website/footerComponents"
import { useStaticQuery, graphql } from "gatsby"
require("../js/main.js")

export const popularSummariesIds = [
  "Q5p2OHQFYc",
  "WZaVm8RYpU",
  "y6bDHYWVIx",
  "5GFnf26Yuc",
  "JmV1IelXx2",
  "NbkDax7bFK",
  "76Kk7ROyQ7",
  "cZ18J1JrMn",
  "VQDBeFuUBz",
  "oX1u8SES5Z",
  "NsmGm8EyPV",
  "SzHIWJOE0Q",
  "tTNehLOx9s",
  "LPbfruYlwp",
]

const PageTitle = styled.h1`
  font-family: Cairo, sans-serif;
  font-size: 18px;
  font-weight: 700;
  margin: 0;
  margin-top: 20px;
`

const PageSubTitle = styled.h2`
  font-family: Cairo, sans-serif;
  font-size: 16px;
  line-height: 25px;
  color: grey;
  max-width: 800px;
  font-weight: 400;
  margin: 0;
  margin-top: 10px;
  margin-bottom: 20px;
`

const SectionTitle = styled.h2`
  font-family: Cairo, sans-serif;
  font-size: 14px;
  font-weight: 700;
  margin: 0;
`

const CategorySectionContainer = styled.div`
  margin-top: 20px;
`

const SearchInput = styled.input`
  font-family: Cairo, sans-serif;
  font-size: 14px;

  margin: 15px 0;
  padding: 10px;
  color: black;

  background-color: ${colors.superLightGrey};
  border: 0;
  ::placeholder,
  ::-webkit-input-placeholder {
    color: grey;
  }
  :-ms-input-placeholder {
    color: grey;
  }
`

const NoResultsText = styled.p`
  font-family: Cairo, sans-serif;
  color: grey;
  font-size: 14px;
`

const SectionHeader = ({ title, ...props }) => {
  return (
    <Container style={{ marginBottom: 15, width: "85%", ...props.style }}>
      <SectionTitle>{title}</SectionTitle>
      <LineSeparator margin={10} />
    </Container>
  )
}

const CategorySummariesCardsList = ({ title, summaries, id }) => {
  return (
    <CategorySectionContainer id={id}>
      {title && <SectionHeader title={title} />}

      <Container isRow>
        {summaries.length > 0 ? (
          summaries.map(summary => {
            return (
              <SummaryCard
                summary={summary}
                style={{ marginLeft: 20 }}
              ></SummaryCard>
            )
          })
        ) : (
          <NoResultsText>
            لا يوجد اسم كتاب او اسم مؤلف يتوافق مع كلمة البحث
          </NoResultsText>
        )}
      </Container>
    </CategorySectionContainer>
  )
}

export default props => {
  const {
    site: {
      siteMetadata: {
        categoriesMeta: { activeCategoriesList },
      },
    },
  } = useStaticQuery(
    graphql`
      {
        site {
          siteMetadata {
            categoriesMeta {
              activeCategoriesList
            }
          }
        }
      }
    `
  )

  const summaries = props.pageContext.summaries
  const categoryName = props.pageContext.categoryName

  const pageTitle = props.pageContext.pageTitle

  // add some text to acknowledge engzketab app to build your knowledge
  const pageDescription = `تصفح (${summaries.length}) ${pageTitle} مكتوبة بلغة بسيطة بحيث ، لا تتعدي مدة قرائتهم ٢٠ دقيقة`

  

  const currentActiveIndex = activeCategoriesList.indexOf(categoryName) + 1 // account for all summaries option
  const menuItems = [
    { title: "كل الملخصات", href: "/ملخصات كتب" },
    ...activeCategoriesList.map(categoryName => {
      const seoTitle = collectionNamesMappedForSEO[categoryName]

      return {
        title: `ملخصات ${seoTitle}`,
        href: `/ملخصات كتب ${seoTitle}`,
      }
    }),
  ]

  
  
  
  const [searchText, setSearchText] = useState("")

  const searchSummariesResults = summaries.filter(summary => {
    return (
      (summary.title && summary.title.includes(searchText)) ||
      (summary.authorRef && summary.authorRef.title.includes(searchText))
    )
  })

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />

        <meta name="twitter:card" content="summary" />

        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:description" content={pageDescription} />

        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:site_name" content="تطبيق انجز كتاب" />

        <link rel="icon" type="image/png" href={favicon} />

        
      </Helmet>

      <WebsiteMenu activeItemIndex={1} />

      <StaticHeader />
      <QesetKhabrAd />
      <Menu menuItems={menuItems} activeItemIndex={currentActiveIndex} />
      <LineSeparator margin={5} />
      <Container>
        <Container
          isCenter={true}
          style={{
            width: "100%",
            maxWidth: "900px",
            paddingLeft: 10,
            paddingRight: 10,
          }}
        >
          <PageTitle>{pageTitle}</PageTitle>
          <PageSubTitle>{pageDescription}</PageSubTitle>
          {/* <SearchInput
            placeholder={"ادخل اسم الكتاب او اسم المؤلف لتبدأ البحث"}
            onChange={e => setSearchText(e.target.value)}
          /> */}

          {searchText && searchText.length > 0 ? (
            <CategorySummariesCardsList
              title={`نتائج البحث (${searchSummariesResults.length})`}
              summaries={searchSummariesResults}
            />
          ) : (
            <Container>
              <CategorySummariesCardsList summaries={summaries} />
            </Container>
          )}
        </Container>

        {/* <EmailSubscription /> */}
        <Footer />
      </Container>
    </div>
  )
}
